<template>
  <div class="bg-white my-1 pb-1 px-1 rounded-lg">
    <h2 class="font-weight-bolder font-medium-5 pt-2">
      {{ $t('Inventory') }}
    </h2>
    <l-table-list-collector
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumns"
      :is-searchable="false"
      :fetched-data="inventoryPermanentTransfer.inventory"
      :can-create="ACCESS_ABILITY_INVENTORY_PERMANENT_CREATE"
      :is-footer="true"
    >
      <template #pagination>
        {{ }}
      </template>
      <template #cell(actions)="{}">
        <div
          class="d-flex justify-content-center"
        >
          <Can
            :i="ACCESS_ABILITY_INVENTORY_PERMANENT_CREATE.action"
            :a="ACCESS_ABILITY_INVENTORY_PERMANENT_CREATE.subject"
          >
            <feather-icon
              icon="AlignLeftIcon"
              class="cursor-pointer border-dotted mr-1"
              style="width: 40px; height: 28px; padding: 5px"
            />
            <feather-icon
              icon="LCalendarsIcon"
              class="cursor-pointer border-dotted"
              style="width: 40px; height: 28px; padding: 5px"
              @click="gotoNewPage({ name: 'home-inventory-calendar', params: { id: itemId } }, $event)"
            />
          </Can>
        </div>
      </template>
      <template #cell(warehouse)="{data: { item }}">
        <div class="pl-2">
          {{ getValueFromGivenObjectByKey(item, 'warehouse', '—') }}
        </div>
      </template>
      <template #cell(stock)="{data: {item}}">
        <div class="d-block text-center">
          {{ getValueFromGivenObjectByKey(item, 'stock', '—') }}
        </div>
      </template>
      <template #cell(available)="{data: {item}}">
        <div class="pl-3">
          {{ getValueFromGivenObjectByKey(item, 'available', '—') }}
        </div>

      </template>
      <template #cell(amount_to_transfer)="{data}">
        <div class="pl-2">
          <increment-decrement-input
            ref="incrementDecrementInputStockItems"
            :item="data.item"
            :is-disabled-minus="data.item.amount === 0 || !isSelectedSameWarehouse(data.item.warehouse_id)"
            :is-disabled-plus="isDisabledAmount(data.item.warehouse_id)"
            item-key-name-for-amount="amount"
            :on-index-comparison="true"
            class="d-flex justify-content-start"
            @onIncrementDecrementAction="onIncrementDecrementAction"
          >
            <template #switch-off-action>
              <div>
                {{ data.item.amount ? data.item.amount : 0 }}
              </div>
            </template>
          </increment-decrement-input>
        </div>
      </template>
    </l-table-list-collector>
  </div>
</template>
<script>

import LTableListCollector from '@/views/components/LTableListCollector.vue'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import config from '../../../create/createTransferByInventoryQty/config'

export default {
  components: { LTableListCollector },
  computed: {
    inventoryPermanentTransfer() {
      return this.$store.state[this.MODULE_NAME].inventoryPermanentTransferFormByInventoryQty
    },
    itemId() {
      return this.$route.params.id
    },
    warehouse() {
      return this.$store.state.app.warehouse
    },
    currentWarehouse() {
      return this.$store.state.app.warehouse
    },
  },
  methods: {
    getValueFromGivenObjectByKey,
    onIncrementDecrementAction(data) {
      // console.log(data)
    },
    isSelectedSameWarehouse(warehouseId) {
      const currentWarehouseId = this.currentWarehouse.id

      return warehouseId === currentWarehouseId
    },
    isDisabledAmount(warehouseId) {
      const assetsList = this.inventoryPermanentTransfer?.inventory
      const assetItem = assetsList?.find(i => i.warehouse_id === warehouseId)

      if (!assetItem) return true

      return assetItem.available === assetItem.amount || !this.isSelectedSameWarehouse(warehouseId)
    },
  },
  setup() {
    const {
      MODULE_NAME, autoSuggestTableColumns, tableColumns, ACCESS_ABILITY_INVENTORY_PERMANENT_CREATE,
    } = config()

    return {
      MODULE_NAME,
      tableColumns,
      autoSuggestTableColumns,
      ACCESS_ABILITY_INVENTORY_PERMANENT_CREATE,
    }
  },

}
</script>
