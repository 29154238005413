var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white my-1 pb-1 px-1 rounded-lg"},[_c('h2',{staticClass:"font-weight-bolder font-medium-5 pt-2"},[_vm._v(" "+_vm._s(_vm.$t('Inventory'))+" ")]),_c('l-table-list-collector',{ref:"lTableRef",attrs:{"module-name":_vm.MODULE_NAME,"table-columns":_vm.tableColumns,"is-searchable":false,"fetched-data":_vm.inventoryPermanentTransfer.inventory,"can-create":_vm.ACCESS_ABILITY_INVENTORY_PERMANENT_CREATE,"is-footer":true},scopedSlots:_vm._u([{key:"pagination",fn:function(){return [_vm._v(" "+_vm._s()+" ")]},proxy:true},{key:"cell(actions)",fn:function(ref){return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('Can',{attrs:{"i":_vm.ACCESS_ABILITY_INVENTORY_PERMANENT_CREATE.action,"a":_vm.ACCESS_ABILITY_INVENTORY_PERMANENT_CREATE.subject}},[_c('feather-icon',{staticClass:"cursor-pointer border-dotted mr-1",staticStyle:{"width":"40px","height":"28px","padding":"5px"},attrs:{"icon":"AlignLeftIcon"}}),_c('feather-icon',{staticClass:"cursor-pointer border-dotted",staticStyle:{"width":"40px","height":"28px","padding":"5px"},attrs:{"icon":"LCalendarsIcon"},on:{"click":function($event){return _vm.gotoNewPage({ name: 'home-inventory-calendar', params: { id: _vm.itemId } }, $event)}}})],1)],1)]}},{key:"cell(warehouse)",fn:function(ref){
var item = ref.data.item;
return [_c('div',{staticClass:"pl-2"},[_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'warehouse', '—'))+" ")])]}},{key:"cell(stock)",fn:function(ref){
var item = ref.data.item;
return [_c('div',{staticClass:"d-block text-center"},[_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'stock', '—'))+" ")])]}},{key:"cell(available)",fn:function(ref){
var item = ref.data.item;
return [_c('div',{staticClass:"pl-3"},[_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'available', '—'))+" ")])]}},{key:"cell(amount_to_transfer)",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"pl-2"},[_c('increment-decrement-input',{ref:"incrementDecrementInputStockItems",staticClass:"d-flex justify-content-start",attrs:{"item":data.item,"is-disabled-minus":data.item.amount === 0 || !_vm.isSelectedSameWarehouse(data.item.warehouse_id),"is-disabled-plus":_vm.isDisabledAmount(data.item.warehouse_id),"item-key-name-for-amount":"amount","on-index-comparison":true},on:{"onIncrementDecrementAction":_vm.onIncrementDecrementAction},scopedSlots:_vm._u([{key:"switch-off-action",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(data.item.amount ? data.item.amount : 0)+" ")])]},proxy:true}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }