<template>
  <div class="pb-2">
    <portal to="body-top">
      <h4 class="text-center font-weight-bolder mb-1">
        {{ $t('New Inventory Relocation') }}{{ `${inventory.sku ? `—${inventory.sku}` : ' '} — ${inventory.name ? `${inventory.name}` : ''} ` }}
      </h4>
    </portal>
    <quote-status-bar
      :item="quote"
      class="mb-1"
    />
    <div>
      <error-alert
        :error="error"
      />
      <b-form>
        <validation-observer
          ref="refPermanentTransferCreate"
        >
          <skeleton v-if="!inventoryPermanentTransfer" />

          <div v-else>
            <transfer-schedule-form />
            <inventory />
          </div>
          <portal to="body-footer">
            <div class="d-flex mt-2 pb-1 justify-content-between">
              <div>
                <b-button
                  v-if="!isFormChanged"
                  class="cancelBtn font-medium-1 font-weight-bolder"
                  variant="outline-primary"
                  @click="backToList"
                >
                  {{ $t('Back to List') }}
                </b-button>
                <b-button
                  v-else
                  class="cancelBtn font-medium-1 font-weight-bolder"
                  variant="outline-primary"
                  @click="cancelHandler"
                >
                  <feather-icon
                    icon="LCancelIcon"
                    size="16"
                  />
                  {{ $t('Cancel') }}
                </b-button>
              </div>
              <div>
                <b-button
                  variant="success"
                  class="saveBtn d-flex align-items-center font-medium-1 font-weight-bolder"
                  :disabled="!isSchedule || isLoading"
                  @click="submit"
                >
                  <feather-icon
                    icon="LInOutIcon"
                    size="18"
                    class="mr-1"
                  />
                  {{ $t('Schedule') }}
                </b-button>
              </div>
            </div>
          </portal>
        </validation-observer>
      </b-form>
      <b-modal
        v-if="conflict_orders.length"
        id="transfer-conflicts-with-orders"
        ref="modal"
        centered
        ok-variant="success"
        hide-header
        hide-footer
        style="width: 600px"
      >
        <div class="d-flex align-items-center flex-column">
          <h2 class="text-center font-weight-bolder font-medium-5">
            {{ $t('Transfer conflicts with orders:') }}
          </h2>
          <span
            v-for="item in conflict_orders"
            class="d-flex align-items-center flex-column font-weight-bolder font-medium-5"
          >
            <span>{{ item.order_number }}</span>
          </span>
          <p class="text-center font-small-4 py-1">
            {{ $t('Forcing the transfer will break allocation and further corrective actions will be needed to replenish the missing inventory') }}
          </p>
        </div>
        <span class="d-flex align-items-center justify-content-between">
          <b-button
            variant="outline-primary"
            class="font-medium-1 font-weight-bolder"
            @click="$bvModal.hide('transfer-conflicts-with-orders')"
          >
            {{ $t('Go Back') }}
          </b-button>
          <b-button
            style="background-color: #6F1214 !important"
            class="font-medium-1 font-weight-bolder"
            @click="submitForce"
          >
            {{ $t('Force Transfer') }}
          </b-button>
        </span>
      </b-modal>
      <b-modal
        id="scheduled-transfer-order"
        centered
        ok-variant="success"
        hide-header
        hide-footer
        size="lg"
      >
        <div
          class="text-center"
        >
          <span>
            <feather-icon
              icon="LInOutIcon"
              size="18"
              color="green"
              style="margin-right: 4px; margin-bottom: 4px"
            />
            <h4 class="d-inline font-weight-bolder">
              {{ $t('Next Scheduled Transfer Order') }}
            </h4>
          </span>
          <div>
            <b-table
              :fields="modalTableColumns"
              :items="[...inventoryTransferOrderInformationByQty]"
              class="pb-1 px-1"
            >
              <template #cell(order_id)="data">
                <span class="d-block p-0">
                  {{ data.item.order_id }}
                </span>
              </template>
              <template #cell(scheduled)="data">
                <span class="d-block text-left">
                  {{ data.item.scheduled_dispatch }}
                </span>
              </template>
              <template #cell(item_ct)="data">
                <span class="d-block text-left pl-1">
                  {{ data.item.item_ct }}
                </span>
              </template>
            </b-table>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <span>
              <b-button
                variant="outline-primary"
                class="font-medium-1 font-weight-bolder"
                @click="$bvModal.hide('scheduled-transfer-order')"
              >
                {{ $t('Go Back') }}
              </b-button>
            </span>
            <span>
              <b-button
                variant="success"
                class="font-medium-1 font-weight-bolder mr-1"
                @click="submitFormSchedule('force')"
              >
                {{ $t('Create New Instead') }}
              </b-button>
              <b-button
                variant="success"
                class="font-medium-1 font-weight-bolder"
                @click="submitFormSchedule('unite')"
              >
                {{ $t('Add to Order') }}
              </b-button>
            </span>
          </div>
        </div>
      </b-modal>
      <b-s-warning-modal ref="confirm-modal" />
    </div>
  </div>
</template>
<script>

import ErrorAlert from '@/views/components/ErrorAlert.vue'
import {
  BButton, BForm, BModal, BTable,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { getProps, scrollToError } from '@core/utils/utils'
import { format } from 'date-fns'
import Skeleton from '../../components/create/createTransferByInventoryQty/Skeleton.vue'
import config from './config'
import QuoteStatusBar from '../../components/create/common/QuoteStatusBar.vue'
import TransferScheduleForm
from '../../components/create/createTransferByInventoryQty/TransferScheduleForm.vue'
import Inventory from '../../components/create/createTransferByInventoryQty/Inventory.vue'

export default {
  components: {
    Skeleton,
    BModal,
    Inventory,
    TransferScheduleForm,
    BButton,
    BTable,
    BForm,
    ErrorAlert,
    QuoteStatusBar,
    ValidationObserver,
  },
  data() {
    return {
      error: {},
      isLoading: false,
      show: false,
      conflict_orders: [],

    }
  },
  computed: {
    inventoryPermanentTransfer() {
      return this.$store.state[this.MODULE_NAME].inventoryPermanentTransferFormByInventoryQty
    },
    inventoryTransferOrderInformationByQty() {
      return this.$store.state[this.MODULE_NAME].transferOrderScheduledInformation
    },
    inventoryPermanentTransferClone() {
      return this.$store.state.cloneData.transferOrderClone
    },
    inventory() {
      return this.$store.state[this.MODULE_NAME].product
    },
    warehouse() {
      return this.$store.state.app.warehouse
    },
    isFormChanged() {
      return JSON.stringify(this.inventoryPermanentTransfer) !== this.inventoryPermanentTransferClone
    },
    isSchedule() {
      return this.inventoryPermanentTransfer?.inventory?.filter(({ amount }) => amount > 0).length
    },
    formattedDate() {
      const date = {
        fullYear: new Date(this.inventoryPermanentTransfer.scheduled_dispatch).getFullYear(),
        day: new Date(this.inventoryPermanentTransfer.scheduled_dispatch).getDate(),
        month: new Date(this.inventoryPermanentTransfer.scheduled_dispatch).getMonth() + 1,
      }
      const { fullYear, day, month } = date
      const secondMonth = month < 10 ? `0${month}` : month
      const secondDay = day < 10 ? `0${day}` : day
      return [`${fullYear}`, `${secondMonth}`, `${secondDay}`].join('-')
    },
    formattedDateWithString() {
      const date = {
        fullYear: new Date(this.inventoryPermanentTransfer.scheduled_dispatch).getFullYear(),
        day: new Date(this.inventoryPermanentTransfer.scheduled_dispatch).getDate(),
        month: new Date(this.inventoryPermanentTransfer.scheduled_dispatch).toLocaleString('default', { month: 'long' }),
        transferTime: this.inventoryPermanentTransfer.transfer_time,
      }
      const {
        fullYear, day, month, transferTime,
      } = date
      return [`${month}`, `${day}`, `${fullYear}`, `${transferTime}`].join(' ')
    },
    quote() {
      return this.$store.state[this.MODULE_NAME].quoteTransferOrder
    },
  },
  created() {
    const { id } = this.$route.params
    const data = {
      id,
      type: 'catalog',
    }
    this.$store.dispatch(`${this.MODULE_NAME}/getDetails`, data).then(res => {
      const { data } = res.data
      const mappedArray = data.catalog.length > 0 ? data.catalog.map(item => ({ ...item, amount: 0 })) : []
      const transferCreateData = {
        transfer_time: format(new Date(), 'HH:mm'),
        scheduled_dispatch: new Date(),
        purpose: '',
        inventory: mappedArray,
        spec_date_and_time: this.SPECIFIC_DATE,
      }
      this.$store.commit(`${this.MODULE_NAME}/SET_PERMANENT_TRANSFER_FORM_BY_INVENTORY_QTY`, transferCreateData)
      this.$store.commit('cloneData/SET_TRANSFER_ORDER_BY_CLONE', transferCreateData)
      this.$store.commit(`${this.MODULE_NAME}/SET_TRANSFER_ORDER_QUOTE`, {
        state: data.state,
        status: data.status,
      })
    }).catch(err => {
      console.log(err)
    })
    this.$store.dispatch(`${this.MODULE_NAME}/getStockProduct`, { id })
  },
  methods: {
    backToList() {
      this.$router.back()
    },
    cancelHandler() {
      this.$store.commit(`${this.MODULE_NAME}/SET_PERMANENT_TRANSFER_FORM_BY_INVENTORY_QTY`, {})
      this.$store.commit('cloneData/SET_TRANSFER_ORDER_BY_CLONE', {})
      this.$store.commit(`${this.MODULE_NAME}/SET_TRANSFER_ORDER_QUOTE`, {})
    },
    submitFormSchedule(method) {
      const transferTime = String(`${this.inventoryPermanentTransfer.transfer_time.split(':')['0']}:${this.inventoryPermanentTransfer.transfer_time.split(':')['1']}`)
      const data = this.inventoryPermanentTransfer
      const orderId = this.inventoryTransferOrderInformationByQty.id
      const { id } = this.$route.params
      const transferOrder = {
        _id: id,
        type: 'catalog',
        receiving_warehouse: data.receiving_warehouse.id,
        scheduled_dispatch: String(this.formattedDate),
        purpose: data.purpose,
        transfer_time: String(transferTime),
        amount: data.inventory.find(i => i.amount > 0)?.amount ?? 0,
        method,
        unite_id: orderId,
      }
      this.$store.dispatch(`${this.MODULE_NAME}/createTransferOrder`, { transferOrder }).then(res => {
        const { data } = res?.data
        this.$router.push({ name: 'scheduled-transfer-order-page', params: { id: data.id } })
      })
    },
    submitForce() {
      const transferTime = String(`${this.inventoryPermanentTransfer.transfer_time.split(':')['0']}:${this.inventoryPermanentTransfer.transfer_time.split(':')['1']}`)
      const data = this.inventoryPermanentTransfer
      const { id } = this.$route.params
      const transferOrder = {
        _id: id,
        type: 'catalog',
        receiving_warehouse: data.receiving_warehouse.id,
        scheduled_dispatch: String(this.formattedDate),
        purpose: data.purpose,
        transfer_time: String(transferTime),
        amount: data.inventory.find(i => i.amount > 0)?.amount ?? 0,
        method: 'force',
      }
      this.$store.dispatch(`${this.MODULE_NAME}/createTransferOrder`, { transferOrder }).then(res => {
        const { data } = res.data
        this.$router.push({ name: 'scheduled-transfer-order-page', params: { id: data.id } })
      })
    },
    submit() {
      this.$refs.refPermanentTransferCreate.validate()
        .then(success => {
          if (success) {
            const transferTime = String(`${this.inventoryPermanentTransfer.transfer_time.split(':')['0']}:${this.inventoryPermanentTransfer.transfer_time.split(':')['1']}`)
            const data = this.inventoryPermanentTransfer
            const { id } = this.$route.params
            const transferOrder = {
              _id: +id,
              type: 'catalog',
              receiving_warehouse: data.receiving_warehouse.id,
              scheduled_dispatch: String(this.formattedDate),
              purpose: data.purpose,
              transfer_time: String(transferTime),
              amount: data.inventory.find(i => i.amount > 0).amount ?? 0,
              method: '',
            }
            this.isLoading = true
            // this.$refs.modal.show()
            this.$store.dispatch(`${this.MODULE_NAME}/createTransferOrder`, { transferOrder }).then(res => {
              const { data } = res.data
              if (data.method === 'unite') {
                const {
                  id, order_id, scheduled_dispatch, transfer_time, items, requested_by,
                  order_type,
                } = data
                // eslint-disable-next-line camelcase
                const scheduledTime = `${new Date(scheduled_dispatch).toLocaleString('default', { month: 'long', year: 'numeric', day: 'numeric' })} ${transfer_time}`
                const selectObject = {
                  id,
                  order_id,
                  scheduled_dispatch: scheduledTime,
                  item_ct: items,
                  requested_by,
                  order_type,
                  inventory_item: data?.inventory_item,
                }
                this.$store.commit(`${this.MODULE_NAME}/SET_TRANSFER_ORDER_SCHEDULED`, selectObject)
                this.$bvModal.show('scheduled-transfer-order')
              } else if (data.method === 'force') {
                this.conflict_orders = data?.conflict_orders
                this.$bvModal.show('transfer-conflicts-with-orders')
              } else {
                this.$router.push({ name: 'scheduled-transfer-order-page', params: { id: data.id } })
              }
            }).catch(err => {
              this.errorNotification(this, err)
            }).finally(() => {
              this.isLoading = false
            })
          } else {
            scrollToError(this, this.$refs.refPermanentTransferCreate)
          }
        })
    },
  },
  setup() {
    const {
      MODULE_NAME, SPECIFIC_DATE, modalTableColumns, permanentTransferCreateFields,
    } = config()

    return {
      MODULE_NAME,
      SPECIFIC_DATE,
      getProps,
      modalTableColumns,
      permanentTransferCreateFields,
    }
  },
}
</script>
<style lang="scss">
.custom-radio-group-input {
  padding-left: 10px !important;
  margin-bottom: 8px !important;

  & .custom-control-label {
    position: relative !important;
    width: 100% !important;

    &::before {
      left: 95.2% !important;
    }
  }
}
</style>
