<template>
  <div>
    <div class="bg-white px-1 rounded-lg">
      <h2 class="font-weight-bolder font-medium-5 pt-2">
        {{ $t('Transfer Schedule') }}
      </h2>
      <div>
        <label class="font-small-4 font-weight-bolder pl-1 mt-2">{{ $t('Asset(s) allocation') }}</label>
        <b-row cols-md="12">
          <b-col
            v-for="item in 2"
            :key="item"
          >
            <b-skeleton />
          </b-col>
        </b-row>
        <b-row cols-md="12">
          <b-col
            v-for="item in 3"
            :key="item"
            md="6"
          >
            <b-skeleton
              type="input"
              class="w-100 mb-2"
            />
          </b-col>
          <b-col
            md="6"
            style="height: 200px"
          >
            <b-skeleton
              style="height: 200px"
              class="w-100 mb-2 h-100 mb-1"
            />
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="bg-white px-1 mt-1 rounded-lg">
      <h2 class="font-weight-bolder font-medium-5 pt-2">
        {{ $t('Inventory') }}
      </h2>
      <b-skeleton-table
        :rows="4"
        :columns="3"
        :table-props="{ bordered: false, striped: false }"
      />
    </div>
  </div>
</template>
<script>

import {
  BCol, BRow, BSkeleton, BSkeletonTable,
} from 'bootstrap-vue'

export default {
  name: 'Skeleton',
  components: {
    BCol,
    BRow,
    BSkeleton,
    BSkeletonTable,
  },
}
</script>
